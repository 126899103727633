<template>
  <div id="homeextra">
    <div class="row">
      <div class="col-md-6">
        <img  src="@/assets/img/subelerImg.png"  />
      </div>
      <div class="col-md-6">
        <div class="subelerContent">
          <span>ŞUBELER</span>
          <h1>Size En Yakın Şubemizi Bulun!</h1>
          <p>
            {{ siteName2 }} olarak müşterilerimize uygun ve kaliteli ürünleri
            sunmak için var gücümüzle çalışmaktayız.
          </p>

          <a href="https://kurumsal.gourmetgarage.com.tr/Magazalarimiz" target="_blank" class="formButon"
            ><span>ŞUBELERİMİZİ GÖRÜNTÜLEYİN</span></a
          >
        </div>
      </div>
    </div>

    <EssenInfoBoxes />

    <section class="mobileApp">
      <div class="container">
        <div class="row">
          <div class="col-xl-5 offset-xl-1 col-md-6">
            <div class="mobileAppContent">
              <h2>Mobil uygulamamızdan hızlıca sipariş verebilirsiniz.</h2>
              <ul>
                <li>
                  <a
                    href="https://apps.apple.com/tr/app/gourmet-garage/id1517026117"
                    class="hazirlaniyor"
                  >
                    <img
                      src="@/assets/img/appstoreButon.png"
                  /></a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.marketyo.gourmetgarage&hl=tr&gl=TR"
                    class="hazirlaniyor"
                  >
                    <img
                    src="@/assets/img/googleplayButton.png"
                  /></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <img src="@/assets/img/mobileAppBg.png"  />
          </div>
        </div>
      </div>
    </section>

    <ul class="fixedLinks">
      <li>
        <a
          href="https://api.whatsapp.com/send?phone=905494505450"
          class="whatsapp"
          target="_blank"
          ><span>Whatsapp Sipariş</span
          ><i class="fa fa-whatsapp" style="color: #ffffff"></i
        ></a>
      </li>
    </ul>
  </div>
</template>

<script>
import EssenInfoBoxes from "@/components/EssenInfoBoxes";

import { siteName } from "../zdcconfig/zdconfig.js";

export default {
  components: {
    EssenInfoBoxes,
  },
  data() {
    return {
      siteName2: siteName,
    };
  },
};
</script>

<style scoped>
[v-cloak] > * {
  display: none;
}

@import url("https://fonts.cdnfonts.com/css/roboto");
@media (max-width: 990px) {
  #homeextra {
    display: none !important;
  }
}
.subelerContent {
  position: relative;
  top: 50%;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  transform: translateY(-50%);
}

.subelerContent h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}

.subelerContent span {
  font-size: 14px;
  background: #5eae53;
  padding: 8px;
  border-radius: 8px;
  color: #fff;
  display: inline-block;
  margin: 20px 0;
}
.subelerContent:after {
  content: "";
  background-image: url('~@/assets/img/subelerContentBg.png');
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  right: 0;
  top: 0;
  background-position: right top;
  z-index: -1;
}

.mobileApp {
  background: #a42336;
  padding: 30px 0 0;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}

.mobileApp .mobileAppContent {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.mobileApp h2 {
  font-weight: bold;
  font-size: 40px;
  color: #fff;
  line-height: 64px;
  padding-right: 20px;
}

.fixedLinks {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 111;
}
.fixedLinks li {
  display: inline-block;
  clear: both;
  display: block;
  margin-top: 5px;
  float: right;
}
.mobileApp ul {
  padding: 0;
  margin: 60px 0 30px;
}

.mobileApp ul li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
}
.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

.fixedLinks li a.whatsapp {
  border-color: #7dc160;
  background: #7dc160;
}

.fixedLinks li a.whatsapp .fa {
  background: #48a81f;
}

.fixedLinks li a span {
  position: relative;
  float: left;
  transition: ease all.3s;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #fff;
  line-height: 30px;
  padding: 0 25px 0 10px;
  font-weight: 500;
  background: #48a81f;
  margin-right: -25px;
}

.fixedLinks li:hover a span {
  /* width: 104px; */
  /* position: relative; */
  /* float: left; */
  /* color: #fff; */
  /* font-weight: 600; */
  /* padding: 6px 12px; */
  /* transition: ease all.3s; */
  /* opacity: 1; */
  /* height: 18px; */
  /* display: block; */
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
}

.fixedLinks li a {
  background: #ddd;
  float: right;
  position: relative;
  border: 4px solid #aaa;
  border-radius: 20px;
  overflow: hidden;
  transition: ease all 0.3s;
}

.fixedLinks li a .fa {
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 21px;
  border-radius: 20px;
  background: #aaa;
  color: #fff;
  float: right;
  position: relative;
  z-index: 1111;
}
</style>
