export const siteName = "Gourmet Garage";
export const siteNameUpper = "GOURMET GARAGE";
export const googleStoreUrl =
  "https://play.google.com/store/apps/details?id=com.marketyo.gourmetgarage&hl=tr&gl=TR";
export const appleStoreUrl =
  "https://apps.apple.com/tr/app/gourmet-garage/id1517026117";

export const siteMailList = [
  {
    mail: "online@gourmetgarage.com.tr",
    subject: "Bize Mail Bırakın",
  },
];

export const sitePhoneList = [
  { phone: "0 (850) 622 75 00", info: "" },

];

export const eklenenMiktarKatsayisi = 0.1;


export const askPlatformMessage = true; // yonlendirme sorulacak mi
export const askPlatformCounter = 5; // her kac giriste sorulacak (0 ve 1 icin surekli sorar)
export const useAskNeverButton = false; // bir daha sorma butonu aktif mi
